// lightmetrics colors

// primary

$light-primary: #2898a2;
$light-primary-contrast: #ffffff;

$dark-primary: #2898a2;
$dark-primary-contrast: #ffffff;

// accent/secondary

$light-accent: #8b2682;
$light-accent-contrast: #ffffff;

$dark-accent: #e192d6;
$dark-accent-contrast: #212121;

// common

$page-background: #2898a22f;

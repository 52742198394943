// font variables

$large-font-size: 20px;
$medium-font-size: 18px;
$heading-font-size: 16px;
$body-font-size: 14px;
$caption-font-size: 12px;

$light-font-weight: 300;
$normal-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 600;

$font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
